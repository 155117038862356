<template>
  <div class="Notifications content">
    <v-container v-if="!isNewItem">
      <v-card>
        <v-card-text>
          <div class="bar">
            <div class="bar">
              <!-- <TextSearch></TextSearch> -->
            </div>
            <v-btn @click="isNewItem = true" x-large color="secondary">
              Új Üzenet
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
    <v-container v-if="!isNewItem">
      <v-data-table hide-default-footer :headers="headers" :items="data">
        <template v-slot:item="row">
          <tr>
            <td class="text-xs-right">{{ formatDate(row.item.createdAt) }}</td>
            <td class="text-xs-right">{{ row.item.title }}</td>
            <td class="text-xs-right">{{ row.item.message }}</td>
            <td v-if="row.item.adminUser" class="text-xs-right">
              {{ row.item.adminUser.lastName }}
              {{ row.item.adminUser.firstName }}
            </td>
            <!--        <td class="text-xs-right">
               <v-btn icon @click="deleteItem(row.item)" elevation="1">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </td> -->
          </tr>
        </template>
        <template v-slot:no-data>
          <p>{{ $t("app.noData") }}</p>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("app.deleteWarning") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">
            {{ $t("app.cancel") }}
          </v-btn>
          <v-btn @click="finalize()" color="red lighten-1">
            {{ $t("app.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container v-if="isNewItem">
      <v-card>
        <v-card-title>Új üzenet</v-card-title>
        <v-card-text>
          <!-- <v-checkbox v-model=""></v-checkbox> -->
          <v-radio-group v-model="radioGroup">
            <v-radio label="Összes kereskedő" value="0"></v-radio>
            <v-radio
              label="Az első 10 helyezett (Bolt és annak a felhasználói)"
              value="1"
            ></v-radio>
            <v-radio
              label="A 10-20. helyezett (Bolt és annak a felhasználói)"
              value="2"
            ></v-radio>
          </v-radio-group>
          <v-spacer></v-spacer>
          <v-form v-model="isNewItemValid">
            <v-text-field
              label="Cím"
              outlined
              v-model="messageModel.title"
              required
              :rules="defaultRules"
            ></v-text-field>
            <v-textarea
              label="Üzenet"
              outlined
              v-model="messageModel.message"
              required
              :rules="defaultRules"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-card-actions>
            <v-btn
              :disabled="!isNewItemValid"
              x-large
              color="secondary"
              @click="submit()"
            >
              {{ $t("form.send") }}
            </v-btn>
            <v-btn x-large @click="reset()">
              {{ $t("form.cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import messageBus from "../messageBus.js";
import eventBus from "../eventBus.js";
import moment from "moment";
import {
  fv,
  getMultipartHeader,
  getLoggedInHeader,
  toQueryString,
} from "../util/functions.js";
import { API_BASE } from "../util/constants.js";

const MESSAGE_MODEL = {
  title: null,
  message: null,
  topic: null,
  role: null,
};
export default {
  name: "Notifications",
  components: {
    // TextSearch,
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("agent.createdOn"),
          sortable: false,
        },
        {
          text: "Cím",
          sortable: false,
        },
        {
          text: "Üzenet",
          sortable: false,
        },
        {
          text: "Létrehozta",
          sortable: false,
        },
      ],
      defaultRules: [(v) => !!v || "Kötelező"],
      emailRules: fv.emailRules,
      isNewItem: false,
      isNewItemValid: false,
      data: [],
      activeItem: null,
      activeItems: [],
      dialogs: [],
      dialog: false,
      isEdit: false,
      currentPage: 1,
      radioGroup: "0",
      local: {
        isPending: false,
      },
      filter: {
        keyword: "Keres",
        pending: true,
      },
      messageModel: Object.assign(MESSAGE_MODEL, {}),
    };
  },
  created() {
    eventBus.$on("QUIT_EDIT", (data) => {
      this.activeItem = null;
      this.isEdit = false;
    });
  },
  mounted() {
    this.getList();
  },
  computed: {
    activeItemOriginal() {
      if (!this.activeItem) return null;
      return this.data.find((item) => item.id == this.activeItem.id);
    },
  },
  methods: {
    getList() {
      this.$store
        .dispatch("getCollection", {
          end: `/admin/push-notifications/page/${this.currentPage}/filtered?keyword=`,
          data: null,
        })
        .then((res) => {
          this.data = res.data;
        });
    },
    handlePagination() {
      this.getList();
    },
    deleteItem(item) {
      this.activeItem = item;
      this.dialog = true;
    },
    editItem(item) {
      this.isEdit = true;
      this.activeItem = item;
    },
    finalize() {
      console.log(this.activeItem);
      this.$store
        .dispatch("removeItem", `admin/admin-users/${this.activeItem.id}`)
        .then((res) => {
          messageBus.$emit("onNewMessage", {
            type: "success",
            label: this.$t("message.success"),
          });
          this.getList();
          this.activeItem = null;
          this.dialog = false;
        });
    },
    submit() {
      let end = "topic";
      if (this.radioGroup === "1") end = "top10";
      if (this.radioGroup === "2") end = "from10to20";
      let data = {
        title: this.messageModel.title,
        message: this.messageModel.message,
        topic: "string",
        token: "string",
      };
      const requestOptions = {
        method: "POST",
        headers: getLoggedInHeader(localStorage.getItem("token")),
        body: JSON.stringify(data),
        redirect: "follow",
      };
      fetch(`${API_BASE}admin/push-notifications/send/${end}`, requestOptions)
        .then((response) => {
          if (response.ok) {
            messageBus.$emit("onNewMessage", {
              type: "success",
              label: this.$t("message.success"),
            });
            this.isNewItem = false;
            this.getList();
          }
        })
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    },
    formatDate(date) {
      return moment.unix(date).format("YYYY.MM.DD");
    },
    reset() {
      this.messageModel = {
        title: null,
        message: null,
        topic: null,
        role: null,
      };
      this.isNewItem = false;
    },
  },
};
</script>
